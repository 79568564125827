<template>
  <!-- 此页面为不同项目域名的演示流程进件页面（不调接口） -->
  <div class="container pr" v-cloak>
    <!-- 坐标城市 -->
    <div
      class="address pa u-flex u-col-center u-row-between"
      @click="handleClickPosition"
    >
      <img class="icon-position" src="../assets/images/icon_position.png" />
      <span>{{ addressPositionName }}</span>
    </div>

    <!-- 顶部数字滚动 -->
    <div class="round-con pr">
      <div class="round-div pr" :class="stepLineMove ? 'step-line-move' : ''">
        <img src="../assets/images/icon_round.png" class="round-img pr" />
      </div>
      <!-- 刻度 -->
      <ul class="scale pa">
        <li
          v-for="item in 24"
          :key="item"
          :style="{
            transform: `rotate(${item * 15}deg)`,
          }"
        />
      </ul>

      <div class="round-cont-info pa u-flex-col u-col-center u-row-center">
        <!-- logo -->
        <div class="u-flex">
          <img class="logo" src="../assets/images/interactivePro_logo.png" />
          <span class="name-title">点创钱包</span>
        </div>

        <!-- 最高可领取额度 -->
        <p>最高可领取额度（元）</p>

        <div class="pr">
          <count-to
            :start-val="0"
            :end-val="200000"
            :duration="2000"
            class="pr"
          />
          <!-- 网格线背景 -->
          <img
            class="pa line-solid-bg"
            src="../assets/images/line_solid_bg.png"
          />
        </div>
      </div>
    </div>

    <!-- 最长免息30天 -->
    <p class="u-text-center interest-free">
      请填写真实的身份信息，提交后将无法修改
    </p>

    <!-- 注册表单 -->
    <div class="register-form">
      <!-- 姓名 -->
      <van-cell-group class="inp-elem-box">
        <van-field
          v-model.trim="form.userName"
          maxlength="4"
          class="inp-elem"
          placeholder="请输入您的真实姓名"
          @blur="handleBlur(0)"
          clearable
        />
      </van-cell-group>

      <!-- 身份证号 -->
      <van-cell-group class="inp-elem-box">
        <van-field
          v-model.trim="form.idCard"
          maxlength="18"
          class="inp-elem"
          placeholder="请输入您的身份证号"
          @blur="handleBlur(1)"
          clearable
        />
      </van-cell-group>

      <!-- 信用信息 -->
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">
          信用信息
          <span>(多选)</span>
          <small>
            资产<span class="highlight">越多</span>，通过率
            <span class="highlight">越高</span>，额度
            <span class="highlight">越大</span>
          </small>
        </div>
        <van-row :gutter="5">
          <van-col
            v-for="(item, index) in formItemData.creditType"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="8"
            @click="handleSelectCreditInfo(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
        </van-row>
      </div>

      <!-- 芝麻分 -->
      <div class="inp-elem-box inp-elem tags">
        <div class="label-left">芝麻分</div>
        <van-row :gutter="5">
          <van-col
            v-for="(item, index) in formItemData.credit"
            :key="index"
            :class="item.active ? 'active' : ''"
            span="12"
            @click="handleChooseCredit(index)"
          >
            <div class="u-flex u-col-center u-row-center">
              {{ item.dictLabel }}
            </div>
          </van-col>
          <van-col span="12" />
        </van-row>
      </div>
    </div>

    <!-- 提交 -->
    <button class="btn-submit" @click="handleSubmit">提交</button>

    <!-- 按钮底部提示 -->
    <div class="u-flex btn-submit-tips u-row-center">
      <van-icon size="0.35rem" name="bulb-o" />
      <p>数据加密保护，仅用于申请审核</p>
    </div>

    <!-- 底部 -->
    <div class="bottom u-text-center">
      年化利率低于10%(单利)<br />
      本产品不为在校提供借款服务<br />
      请根据个人能力合理贷款，合理消费，避免逾期<br />
      贷款额度、放款时间以实际审批为准<br />
      <small>
        郑重声明：本平台只程供导流报务，放款由银行等金融机构提供，所有贷款申请在未成功放款前不收取任何费用，为了保证您的资金安全，请不要相信任何支付费用的信息、邮件、电话等。 </small
      ><br />
      京ICP备2021021498号-1
    </div>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />

    <!-- 工作城市下拉选 -->
    <van-popup
      v-model="showPickerCity"
      position="bottom"
      safe-area-inset-bottom
      round
    >
      <van-picker
        show-toolbar
        title="请选择工作城市"
        :columns="areaList"
        @change="onCityChange"
        @confirm="onAreaConfirm"
        @cancel="showPickerCity = false"
        value-key="localName"
      />
    </van-popup>
  </div>
</template>

<script>
import myBMap from "../utils/myBMap"; // 引入百度地图
import * as API_Common from "@/api/common.js";

export default {
  name: "InterProRegisterEntry",
  data() {
    return {
      positionStatus: false, // 定位是否成功，默认false
      address: {
        // 定位地址
        province: "", // 省
        city: "", // 市
      },
      areaList: [
        // 自定义省数据二级结构
        { values: [] },
        { values: [] },
      ],
      showPickerCity: false, // 省市联动弹窗状态
      form: {
        // 提交表单
        userName: "", // 姓名
        idCard: "", // 身份证号码
        credit: "", // 芝麻分
        assets: [], // 个人资产
        gpsCityName: "", // 省市展示名称
        cityId: "", // 城市ID
        provinceId: "", // 省ID
      },
      stepLineMove: false, // 进度条的默认状态
      showPop: false, // 是否微信打开蒙层状态
      formItemData: {
        // 表单数据集合
        credit: [
          // 芝麻分集合
          { dictValue: "无", dictLabel: "无", active: false },
          { dictValue: "600以下", dictLabel: "600以下", active: false },
          { dictValue: "600-650", dictLabel: "600-650", active: false },
          { dictValue: "650-700", dictLabel: "650-700", active: false },
          { dictValue: "700以上", dictLabel: "700以上", active: false },
        ],
        creditType: [
          // 个人资产集合
          { dictValue: 1, dictLabel: "有房", active: false },
          { dictValue: 2, dictLabel: "有车", active: false },
          { dictValue: 3, dictLabel: "有公积金", active: false },
          { dictValue: 4, dictLabel: "有社保", active: false },
          { dictValue: 5, dictLabel: "有商业保险", active: false },
          { dictValue: 6, dictLabel: "企业主", active: false },
        ],
      },
      regTest: {
        regIdcard:
          /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, // 身份证号码正则校验
      },
    };
  },
  mounted() {
    // 自定义网站ico
    this.updateIcon();

    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }

    this.checkPosition(); // 获取是否支持定位

    // 顶部环形滚动效果
    setTimeout(() => {
      this.stepLineMove = true;
    }, 10);
  },
  computed: {
    addressPositionName() {
      // 顶部定位名称
      if (this.positionStatus) {
        return `${this.address.province} ${this.address.city}`;
      } else {
        if (this.form.gpsCityName) {
          return this.form.gpsCityName;
        } else {
          return "定位失败";
        }
      }
    },
  },
  methods: {
    // 自定义网站ico
    updateIcon() {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.rel = "shortcut icon";
      link.type = "image/x-icon";
      link.href = require("../assets/images/interactivePro_logo.png");
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    // 校验访问环境是否支持定位
    checkPosition() {
      if (navigator.geolocation) {
        this.initBMap();
      } else {
        this.$toast("当前浏览器不支持定位，请手动选择定位");
        this.manualPosition();
      }
    },

    // 初始化百度地图定位
    initBMap() {
      myBMap.init().then((BMap) => {
        let that = this;
        let geolocation = new BMap.Geolocation();

        geolocation.enableSDKLocation(); // 开启SDK辅助定位
        // 创建百度地理位置实例，代替 navigator.geolocation
        geolocation.getCurrentPosition(
          function (r) {
            if (r) {
              /*
              关于this.getStatus()返回状态码示例说明
              BMAP_STATUS_SUCCESS 检索成功。对应数值“0”
              BMAP_STATUS_CITY_LIST 城市列表。对应数值“1”
              BMAP_STATUS_UNKNOWN_LOCATION 位置结果未知。对应数值“2”
              BMAP_STATUS_UNKNOWN_ROUTE 导航结果未知。对应数值“3”
              BMAP_STATUS_INVALID_KEY 非法密钥。对应数值“4”
              BMAP_STATUS_INVALID_REQUEST 非法请求。对应数值“5”
              BMAP_STATUS_PERMISSION_DENIED 没有权限。对应数值“6”
              BMAP_STATUS_SERVICE_UNAVAILABLE 服务不可用。对应数值“7”
              BMAP_STATUS_TIMEOUT 超时。对应数值“8”
            */
              if (this.getStatus() === 0) {
                that.positionStatus = true;
                that.address.province = r.address.province; //返回当前省份
                that.address.city = r.address.city; //返回当前城市
                that.form.gpsCityName = that.address.city;
              } else {
                that.$toast.fail("定位失败，请手动选择城市");
                that.manualPosition();
              }
            }
          },
          function () {
            that.$toast.fail("定位失败，请手动选择城市");
            that.manualPosition();
          },
          { province: "baidu" }
        );
      });
    },

    // 手动选择定位
    manualPosition() {
      this.positionStatus = false;
    },

    // 监听顶部地理位置按钮点击
    handleClickPosition() {
      if (!this.positionStatus) {
        this.getArea(0, 0);
        this.showPickerCity = true;
      }
    },

    // 监听表单blur
    handleBlur(index) {
      switch (index) {
        case 0: // 姓名输入框失去光标
          if (this.form.userName === "") {
            this.$toast("请输入正确的姓名");
          }
          break;
        case 1: // 身份证输入框失去光标
          if (this.form.idCard !== "") {
            if (!this.regTest.regIdcard.test(this.form.idCard)) {
              this.$toast("请输入正确的身份证号码");
            }
          }
          break;
      }
    },

    // 表单提交
    handleSubmit() {
      if (!this.positionStatus && this.form.cityId === "") {
        this.$toast("请选择您所在的城市");
        return;
      } else if (this.form.userName === "") {
        this.$toast("请输入姓名");
        return;
      } else if (this.form.idCard === "") {
        this.$toast("请输入身份证号码");
        return;
      } else if (!this.regTest.regIdcard.test(this.form.idCard)) {
        this.$toast("请输入正确的身份证号码");
        return;
      }

      this.formItemData.creditType.map((i) => {
        if (i.active) {
          this.form.assets.push(i.dictValue);
        }
      });

      if (this.form.assets.length === 0) {
        this.$toast("请至少选择一项资产");
        return;
      }

      if (this.form.credit === "") {
        this.$toast("请选择芝麻分");
        return;
      }

      // 提交进件信息跳转初审成功页面并下载app
      this.$router.push({
        name: "InterProPassedSuccess",
      });
    },

    // 选择芝麻分信用选项
    handleChooseCredit(index) {
      this.formItemData.credit.map((item) => {
        item.active = false;
      });
      this.formItemData.credit[index].active = true;
      this.form.credit = this.formItemData.credit[index].dictValue;
      this.$forceUpdate();
    },

    // 选择信用信息（多选）
    handleSelectCreditInfo(index) {
      // 取消选中
      if (this.formItemData.creditType[index].active) {
        let arr = this.formItemData.creditType.filter((item) => item.active);

        if (arr.length > 1) {
          this.formItemData.creditType[index].active = false;
        } else {
          this.$toast.fail("至少保留一项");
        }
      } else {
        // 选中
        this.formItemData.creditType[index].active = true;
      }
      this.$forceUpdate();
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },

    // 获取省初始化默认传递0默认值省对应下面的城市
    getArea(parentId, index) {
      API_Common.getRegions(parentId).then((res) => {
        if (200 === res.code) {
          this.areaList[index].values = [{ localName: "请选择" }, ...res.data];
          this.areaList = [...this.areaList]; //更新areaList
          this.loading = false;
        } else {
          this.$toast.fail("获取省份失败");
        }
      });
    },

    // 选择省市下拉选的事件监听
    onCityChange(picker, values, index) {
      if (values[index].localName === "请选择" && index !== 1) {
        this.areaList[1].values = [{ localName: "请选择" }];
      } else {
        if (!index) {
          this.getArea(values[0].id, index + 1); //传参 参数为上层选择的地区的id
        }
      }
    },

    // 地址确认按钮点击
    onAreaConfirm(picker) {
      if (
        picker[0].localName === "请选择" ||
        picker[1].localName === "请选择"
      ) {
        this.$toast.fail("请选择完整的省市");
        return false;
      } else {
        this.form.gpsCityName = `${picker[0].localName} ${picker[1].localName}`;
        this.form.provinceId = picker[0].id;
        this.form.cityId = picker[1].id;
        this.showPickerCity = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow: auto;
  background: url("../assets/images/borrow_much_bg.png") no-repeat top left;
  background-size: 100%;
  padding: 0.3rem 0.35rem;

  .van-overlay {
    z-index: 3;
  }
}

.round-con {
  width: 6.64rem;
  height: 3.32rem;
  overflow: hidden;
  margin: 0 auto;
}

.round-div {
  width: 6.64rem;
  height: 6.64rem;
  border-radius: 50%;
  z-index: 1;
  transform: rotate(5deg);
}

.address {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0.3rem;
  right: 0.2rem;
  top: 0.15rem;
  font-size: 0.24rem;
  font-weight: 400;
  color: #3a3a3a;
  padding: 0.1rem;
  z-index: 2;
}

.icon-position {
  width: 0.28rem;
  height: 0.28rem;
  margin-right: 0.04rem;
}

.step-line-move {
  transform: rotate(157deg);
  transition: transform 2s ease-in-out;
}

.round-img {
  width: 6.64rem;
  height: 6.64rem;
}

.scale {
  top: 0;
  left: 0;
  width: 6.64rem;
  height: 6.64rem;
}

.scale > li {
  width: 0.07rem;
  height: 0.21rem;
  background: #44aefe;
  position: absolute;
  left: 48.8%;
  top: 0.4rem;
  transform-origin: center 2.94rem;
  z-index: 0;
}

.line-solid-bg {
  width: 3.84rem;
  height: 1.42rem;
  left: 50%;
  margin-left: -1.92rem;
  top: 0.1rem;
}

.interest-free {
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  color: #989fa6;
  margin: 0.3rem 0;
}

.round-cont-info {
  top: 0.95rem;
  left: 0;
  width: 100%;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  color: #989fa6;

  span {
    font-size: 0.85rem;
    font-weight: 900;
    color: #2857f6;
    z-index: 2;
  }
}

.logo {
  width: 0.7rem;
  height: 0.7rem;
  margin-bottom: 0.1rem;
}

.name-title {
  margin-left: 0.1rem;
  font-size: 0.32rem !important;
}

.register-form {
  background: #ffffff;
  border-radius: 0.16rem;
  padding: 0.3rem;
}

.inp-elem-box {
  margin-bottom: 0.15rem;
}

.inp-elem {
  border-radius: 0.08rem;
  padding: 0.24rem 0.26rem;
  background-color: #f9f9f9;
}

.van-hairline--top-bottom::after {
  display: none;
}

.label-left {
  font-size: 0.28rem;
  font-weight: 400;
  color: #3a3a3a;

  > span {
    color: #c3c2c6;
  }

  > small {
    display: block;
    margin: 0.1rem 0;
  }

  .highlight {
    color: #2857f6;
  }
}

.tags {
  font-size: 0.28rem;
  color: #c3c2c6;
}

.van-col {
  > div {
    height: 0.6rem;
    width: 100%;
    margin-top: 0.1rem;
    background: #ecece7;
    border: 0.02rem solid #ecece7;
    border-radius: 0.06rem;
  }
}

.tags .active {
  > div {
    background: #dde9fe;
    border-color: #2857f6;
    color: #2857f6;
  }
}

.btn-submit {
  margin: 0.3rem 0;
  display: block;
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  background: linear-gradient(90deg, #507cfb, #3563fa);
  font-size: 0.32rem;
  font-weight: 500;
  color: #ffffff;
  border-radius: 0.08rem;
}

.btn-submit-tips {
  font-size: 0.24rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #8884e9;

  > p {
    margin-left: 0.05rem;
  }
}

.bottom {
  margin: 0.3rem -0.1rem 0;
  font-family: PingFangSC, PingFangSC-Regular;
  font-size: 0.24rem;
  font-weight: 400;
  text-align: center;
  color: #8884e9;
  line-height: 0.4rem;
  display: block;
  white-space: pre-line;
}

.toutiao-show-con {
  padding: 0 0.4rem;
  height: 100%;
  overflow: hidden;
}

.toutiao-pup-title {
  margin: 0.4rem 0 0.24rem;
  font-size: 0.26rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #959595;
  text-align: justify;

  > span {
    color: #f88b2d;
  }
}

.toutiao-pup-protocol {
  height: calc(100% - 3.5rem);
  overflow: auto;

  > div {
    height: 45%;
    background: #f7f7f7;
    border-radius: 0.24rem;
    padding: 0.26rem;
    margin-bottom: 0.2rem;
    overflow: hidden;
    transition: height 1s linear;
  }

  > div.expand-height {
    height: auto;
  }

  h4 {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #3c3e41;
  }

  .islink {
    padding: 0.05rem;
    right: 0;
    bottom: 0;
    width: 1.7rem;
    box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
  }
}

.go-on {
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.8rem;
  background: #ffffff;
  box-shadow: 0 0.02rem 0.2rem 0 rgba(0, 0, 0, 0.3);
  padding: 0.16rem 0.4rem 0.68rem;
  font-size: 0.3rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  color: #ffffff;

  > p {
    line-height: 0.96rem;
    background: linear-gradient(90deg, #ffbd56, #ff7e34);
  }
}

.show-protocol-con {
  height: 100%;
  padding: 0.4rem 0.4rem 0;

  > h4 {
    margin-bottom: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 600;
    color: #3c3e41;
  }
}

.show-protocol-info {
  height: calc(100% - 1.45rem);
  overflow: auto;
}

.show-protocol-btn {
  left: 0;
  right: 0;
  border-top: 0.02rem solid #e3e3e3;
  font-size: 0.3rem;
  height: 0.8rem;
  line-height: 0.8rem;
  margin: 0 -0.4rem;
  font-family: PingFangSC, PingFangSC-Regular;
}
</style>
